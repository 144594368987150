<template>
  <div id="kc">
    <touTop :headername="headerName" />
    <div class="stock">
      <div class="left">
        <sider 
        :headername="headerName" 
        :sideMenus="sideMenu" 
         @transkey="getKey"
        />
      </div> 
      <div class="right">
        <router-view/>
      </div>
    </div>
  </div>
</template>
<script>
import touTop from "@/components/touTop/touTop";
import sider from "@/components/sider/sider";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
export default {
  props: ["oneShow", "twoShow"],
  components: {
    touTop,
    sider,
    breadCrumb,
  },
  data() {
    return {
      headerName: "库存系统",
      sideMenu: [
        { key: 3, value: "商品管理" },
        { key: 4, value: "关联到人数" },
        { key: 1, value: "盘点" },
        { key: 0, value: "报损" },
        { key: 2, value: "沽清" },
       
      ],    
    };
  },
  methods: {
     getKey(key) { //获取sider值
      if(key===3){
         this.$router.push({path: '/stock/damaged',query: {key: key}})
      };
      if(key===2){
        this.$router.push({path: '/stock/check',query: {key: key}})
      };
       if(key===4){
        this.$router.push({path: '/stock/sellOut',query: {key: key}})
      };
      if(key===0){
        this.$router.push({path: '/stock/goodsList',query: {key: key}})
      };
      if(key===1){
        this.$router.push({path: '/stock/peopleGoods',query: {key: key}})
      };
    },
  },
};
</script>
<style lang="less" scoped>
.stock {
  width: 100%;
  display: flex;
  height: 95.4vh;
  .left {
    width: 17vw;
    background: rgba(46, 62, 78, 1);
    display: flex;
  }
  .right{
    flex: 1;
   
  }
}
</style>