<template>
  <div class="sider">
    <div class="firstHang">
      <div class="logo">
        <img :src="oemData.upper_left_img" alt="">
      </div>
      <div class="data">{{activemodule}}</div>
    </div>
    <div class="thirdhang">
    <div class="caidan">
    <a-menu
      style="width: 100%"
      :default-selected-keys="[parseInt(this.$route.query.key)?parseInt(this.$route.query.key):0]"
      :default-open-keys="['sub2']"
     
      mode="inline"
      @click="handleClick"
    >
      <a-sub-menu key="sub2" >
        <span slot="title"><a-icon type="sketch" /><span>{{headername}}</span></span>
        <a-menu-item  v-for="(item,index) in sideMenus" :key="index">
          <a-icon type="codepen-circle" :key="item.key"/>
            {{item.value}} 
        </a-menu-item>
      </a-sub-menu>     
    </a-menu>
  </div>

    </div>
   
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["headername","sideMenus"],
  data() {
    return {
        openKeys: ['sub1'], 
        active:0,
        activemodule:''
    };
  },
  created() {},
  computed: {
    ...mapState(["shop_name",'topTabList','activeName' , 'oemData']),
  },
  methods: {
    handleClick(e) {
      //console.log(e)
     this.$emit('transkey',e.key)
    },  
  },
  mounted() {
    this.topTabList.forEach(item => {
      if(item.name === this.activeName){
        this.activemodule = item.title
      }
    }); 
    
  },
  updated() {},
};
</script>
<style lang="less" scoped>
.sider{
  width:17vw;
  background: rgba(46, 62, 78, 1);
  .firstHang{ 
    display: flex;
    align-items: center;
    padding-left:2vw;
    padding:1vh 0;
    line-height: 3vh;
    margin-bottom:0.8vh;
    border-top: 1px solid rgba(61, 76, 91, 1);
    border-bottom: 1px solid rgba(61, 76, 91, 1);
    .logo{
      width:2.5vw;
      height:2.5vw;
      margin-left: 2vw;
      background-color: rgba(255, 103, 0, 1);
      margin-right:1.3vw;
      border-radius: 5px;
       padding: 0.2vh;
      display: flex;
      justify-content:center;
      align-items: center;
      img{
        // height: 100%;
        width: 100%;
        padding-right: 2px;
      }
    }
    .data{
      height:3vh ;
      line-height: 3vh;
      font-size:2.0vh;
      font-weight: 500;
      color:white;     
    }
  }
    .thirdhang{
      .caidan{
        /deep/.ant-menu{
          background: #2e3e4e;
          border:1px solid #2e3e4e
        }
        /deep/.ant-menu:not(.ant-menu-horizontal){
          .ant-menu-item-selected{
            background: #2e3e4e;
        }
        .ant-menu-item-selected{
          color:rgba(255, 103, 0, 1); 
          font-size: 1.8vh;      
       }
       .ant-menu-submenu-selected{
          color:white;        
       }
       span{
         color:white;
         font-size:1.8vh;
       }
        li{
         color:white;
         font-size: 1.8vh;
       }

      }
      /deep/.ant-menu-inline .ant-menu-item::after{
        border:0;
      }
      /deep/.ant-menu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
        background-color:linear-gradient(to right,rgba(255, 103, 0, 1),rgba(255, 103, 0, 1));
      }
    }
}
  }

</style>
