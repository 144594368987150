<template>
  <div>
    <a-breadcrumb v-if="headername">
      <a-breadcrumb-item>{{headername}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="card-title" v-if="siderContent">{{siderContent}}</div>
  </div> 
</template>
<script>

export default {
  props: ["headername",'siderContent'],
  data() {
    return {   
    };
  },
  created() {},
  computed: {
    
  },
  methods: {
   
  
  },
  mounted() {
    
  },
  updated() {},
};
</script>
<style lang="less" scoped>
  /deep/.ant-breadcrumb{
      height:4.6vh;
      background: rgba(237, 238, 242, 1);
      span{
        margin-left:0.5vw;
        line-height: 4.6vh;
        font-size: 0.8vw;
      }
    }
    .card-title{
      color:rgba(101, 97, 101, 1);
      height: 3.5vh;
      line-height: 3.5vh;
      font-size: 2.2vh;
      margin: 1vh 0 1vh 1.6vh;
      font-weight: bold;
      // margin-left:0.8vw;
      // margin-top: 1vh;
    }

</style>
